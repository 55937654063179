'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductFragment, ProductBlankFragment } from '@/gql';
import ProductImageDisplay from '@/components/ProductImageDisplay';
import AddedToCart from '@/components/AddedToCart';
import ProductHeader from '@/components/ProductHeader';
import AddToCartButton from '@/components/AddToCartButton';
import ProductOptions from '@/components/ProductOptions';
import ProductInfo from '@/components/ProductInfo';
import ReportProduct from '@/components/ReportProduct';
import ReturnDetails from '@/components/ReturnDetails';
import { DESKTOP_SCREEN_SIZE, HACK_TEMP_MEXICO_PRODUCT_IDS } from '@/constants';
import { useProductColorOptions } from '@/hooks/use-product-color-options';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import { useProductVariantSizeOptions } from '@/hooks/use-product-variant-size-options';
import { buildSortedPrimaryProductImages, findListingProductVariantSize, isDigitalItem } from '@/lib/product';
import { useListingUrl } from '@/hooks/use-listing-url';
import { useStoreData } from '@/hooks/use-store-data';
import { isNotNullish } from '@/lib/utils';
import analytics from '@/lib/analytics';
import useCartState from '@/stores/cart-state';
import { CartItem } from '@/types/cart';
import styles from './index.module.css';

interface ProductProps {
  product: ProductFragment | undefined;
  variationId?: number;
  handleVariationChange(variationId: number): void;
  sizeId?: number;
  handleSizeChange(sizeId: number): void;
  productDetails?: ProductBlankFragment;
  handleQuickView?: (addedItem: CartItem) => void;
  productContainerClassName?: string;
}
const Product: React.FC<ProductProps> = ({
  product,
  variationId,
  handleVariationChange,
  sizeId,
  handleSizeChange,
  productDetails,
  handleQuickView,
  productContainerClassName,
}) => {
  const { width } = useWindowDimensions();
  const [screenSize, setScreenSize] = useState(width || 0);
  const [addedCartItem, setAddedCartItem] = useState<CartItem | undefined>();
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const stickyAddToCart = screenSize < DESKTOP_SCREEN_SIZE;

  useEffect(() => {
    setScreenSize(width || 0);
  }, [width]);

  useEffect(() => {
    setShowAddedToCart(Boolean(addedCartItem));
  }, [addedCartItem]);
  const { variant, size } = useMemo(() => {
    const { variant: v, size: s } = findListingProductVariantSize(product, variationId, sizeId) || {};
    return { variant: v, size: s };
  }, [product, sizeId, variationId]);
  const orderedProductImages = useMemo(
    () => (variant ? buildSortedPrimaryProductImages(variant, product?.listingThumbnails?.primary || undefined) : []),
    [product?.listingThumbnails?.primary, variant]
  );

  const { colors } = useProductColorOptions(product);
  const { sizes: variantSizes, availableSizes: variantAvailableSizes } = useProductVariantSizeOptions(variant);
  const dismissAddedToCart = useCallback(() => {
    setShowAddedToCart(false);
  }, []);

  const { storeData } = useStoreData();

  const { addToCart } = useCartState();
  const { title = '', description = '', primaryProductId = 0 } = product || {};
  const { productType } = variant || {};
  const { price } = size || {};

  // TO DO: fetch ProductDetails and DeliveryDetails
  const listingUrl = useListingUrl(product, variationId, size?.id || undefined);
  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const buttonSource = handleQuickView ? 'Quickview' : 'PDP';
      if (isNotNullish(product) && isNotNullish(variationId) && isNotNullish(sizeId)) {
        const addedItem = addToCart(product, variationId, sizeId);
        if (addedItem) {
          setAddedCartItem(addedItem);
          const payload = {
            ...addedItem,
            price: price || 0,
            product,
            source: stickyAddToCart ? 'Sticky' : buttonSource,
          };
          analytics.track('add_to_cart', payload, storeData);
          if (handleQuickView) handleQuickView(addedItem);
        }
      }
    },
    [addToCart, handleQuickView, price, product, sizeId, storeData, variationId, stickyAddToCart]
  );

  const isDigital = Boolean(size && isDigitalItem(size));

  return (
    <>
      {showAddedToCart && addedCartItem && product && !handleQuickView && (
        <AddedToCart
          isOpen={Boolean(addedCartItem)}
          handleClose={dismissAddedToCart}
          product={product}
          cartItem={addedCartItem}
          source='PDP'
        />
      )}
      <div className={productContainerClassName || styles.productContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.imageDisplay}>
            <ProductImageDisplay images={orderedProductImages} productUrl={handleQuickView ? listingUrl : undefined} />
          </div>
        </div>
        {/* Product info */}
        <div className={styles.productInfo}>
          <div className={handleQuickView ? styles.mobileHide : styles.productHeader}>
            <ProductHeader
              name={title || ''}
              price={Number(price) || 0}
              currencyCode={product?.currency || ''}
              productType={productType || ''}
            />
          </div>

          <form className={styles.productOptions} onSubmit={handleSubmit}>
            <ProductOptions
              colors={colors}
              sizes={variantSizes}
              availableSizes={variantAvailableSizes}
              selectedColorId={variant?.variationId ?? undefined}
              selectedSize={size?.id ?? undefined}
              handleColorSelection={handleVariationChange}
              handleSizeSelection={handleSizeChange}
              productDetails={productDetails || {}}
            />
            <div
              className={stickyAddToCart && !showAddedToCart ? styles.addToCartSticky : styles.addToCart}
              data-testid={stickyAddToCart && !showAddedToCart ? 'addToCartSticky' : 'addToCart'}
            >
              <AddToCartButton
                availability={
                  !!variant?.inventoryCount &&
                  variant?.inventoryCount > 0 &&
                  variantAvailableSizes.some((availSize) => availSize.id === size?.id)
                }
              />
            </div>
          </form>

          {!isDigital ? <ReturnDetails deliveryType='physical' /> : <p className={styles.sectionSpacing} />}

          {!handleQuickView && (
            <section aria-labelledby='details-heading' className={styles.additionalDetails}>
              <h2 id='details-heading' className='sr-only'>
                Additional details
              </h2>
              {/* Product details */}
              <ProductInfo
                description={description || ''}
                details={productDetails || {}}
                deliveryDetails={{
                  type: isDigital ? 'digital' : 'physical',
                  estimatedShipDate: product?.primaryProduct?.[0]?.productionEndDate ?? '',
                }}
                fulfilledInMexico={HACK_TEMP_MEXICO_PRODUCT_IDS.has(primaryProductId || 0)}
              />
              <ReportProduct listingSlug={product?.url || ''} />
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
