import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import DeliveryDetails from '@/components/DeliveryDetails';
import { ProductBlankFragment } from '@/gql';
import analytics from '@/lib/analytics';
import { Metrics } from '@/types/metrics';
import { useStoreData } from '@/hooks/use-store-data';
import styles from './index.module.css';

const ProductInfoLabels = ['Description', 'Product Details', 'Shipping & Returns'];
const defaultMinContentLength = 132; // max characters fitting in 2 lines
const averageCharacterWidth = 0.45; // approximation for font-family Inter

interface ProductInfoProps {
  description: string;
  details: ProductBlankFragment;
  deliveryDetails: {
    type: string;
    estimatedShipDate?: string;
  };
  fulfilledInMexico?: boolean;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ description, details, deliveryDetails, fulfilledInMexico }) => {
  const [descriptionLabel, productDetailsLabel, shippingDetailsLabel] = ProductInfoLabels;

  const buttonRef = useRef<HTMLButtonElement | null>(null); // Ref to the panel button element
  const [minContentLength, setMinContentLength] = useState<number>(defaultMinContentLength);
  const [openPanel, setOpenPanel] = useState<string | null>(null);

  const { storeData } = useStoreData();
  const togglePanel = (panel: string) => {
    const panelLabel = panel.includes('Shipping')
      ? 'deliverydetails'
      : panel.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    const eventType = `${panelLabel}_accordion`;
    analytics.track(eventType as keyof Metrics, {}, storeData);
    setOpenPanel((prevOpenPanel) => (prevOpenPanel === panel ? null : panel));
  };

  useEffect(() => {
    // if description is shorter than min characters in 2 lines, open Description panel initially
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.getBoundingClientRect().width;
      // Calculate the maximum number of characters per 2 lines based on the button's width and font size
      const maxChars = Math.floor((buttonWidth * 2) / (averageCharacterWidth * 14));
      setMinContentLength(maxChars);
      // Determine if the panel should be initially open based on description length
      if (description.length < maxChars) {
        setOpenPanel(descriptionLabel);
      }
    }
  }, [description.length, descriptionLabel]);

  const renderTabContent = (infoLabel: string) => {
    const productDescription = details?.description?.split('\n').filter((x) => x) || [];
    const currentProductDescription = productDescription.map((item) => (
      <li key={`${productDescription?.indexOf(item)}-${item}`}>{item}</li>
    ));

    switch (infoLabel) {
      case descriptionLabel:
        /* eslint-disable-next-line react/no-danger */
        return <div>{parse(description)}</div>;
      case productDetailsLabel:
        return (
          <ul className={styles.list}>
            {currentProductDescription}
            {details?.fit && <li>{details.fit}</li>}
            {details?.materials && <li>{details?.materials}</li>}
          </ul>
        );
      case shippingDetailsLabel:
        return (
          <DeliveryDetails
            type={deliveryDetails.type}
            shipDate={deliveryDetails.estimatedShipDate}
            fulfilledInMexico={fulfilledInMexico}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div className={styles.mainContainer}>
      {ProductInfoLabels.map((label) => (
        <Disclosure as='div' key={label} className={styles.infoContainer}>
          {() => {
            const isOpen = openPanel === label;
            return (
              <>
                <Disclosure.Button
                  ref={label === descriptionLabel ? buttonRef : null} // Set the ref for the Description button
                  onClick={() => togglePanel(label)}
                  className={styles.infoButton}
                >
                  {label}
                  <span className={styles.iconContainer}>
                    {isOpen ? (
                      <MinusIcon className={styles.icon} aria-hidden='true' />
                    ) : (
                      <PlusIcon className={styles.icon} aria-hidden='true' />
                    )}
                  </span>
                </Disclosure.Button>
                {!isOpen && label === descriptionLabel && description.length > minContentLength && (
                  <div className={styles.panelPreview}>{parse(description)}</div>
                )}
                {isOpen && (
                  <Disclosure.Panel className={styles.infoPanel} static>
                    {renderTabContent(label)}
                  </Disclosure.Panel>
                )}
              </>
            );
          }}
        </Disclosure>
      ))}
    </div>
  );
};

export default ProductInfo;
