import React from 'react';
import CustomImage from '@/components/CustomImage';
import { Tab } from '@headlessui/react';
import { ImageFragment } from '@/gql';
import styles from './index.module.css';

const ProductImageSelector: React.FC<{
  images: ReadonlyArray<ImageFragment>;
  activeIndex: number;
}> = ({ images, activeIndex }) => {
  const thumbnailSize = 75;

  return (
    <Tab.List className={styles.tabList}>
      <div className={styles.imageContainer}>
        {images?.map((image, index) => {
          const borderClass = activeIndex === index ? styles.activeBorder : styles.inactiveBorder;
          return (
            <Tab key={image.label} className={`${styles.group} ${borderClass}`}>
              <span className='sr-only'>{image.label}</span>
              <CustomImage
                src={image.src || ''}
                alt={image.label || ''}
                fill
                priority
                sizes={`${thumbnailSize}px`}
                style={{ objectFit: 'contain' }}
              />
            </Tab>
          );
        })}
      </div>
    </Tab.List>
  );
};

export default ProductImageSelector;
