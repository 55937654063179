import React, { useState } from 'react';
import Link from 'next/link';
import CustomImage from '@/components/CustomImage';
import { Dialog } from '@headlessui/react';
import { ImageFragment } from '@/gql';
import { XMarkIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import styles from './index.module.css';

const ProductImageZoom: React.FC<{
  images: ReadonlyArray<ImageFragment>;
  initialIndex: number;
  productUrl?: string;
}> = ({ images, initialIndex, productUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handleDialog = () => {
    setIsOpen(!isOpen);
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      {productUrl ? (
        <Link href={productUrl} className={styles.imageLink}>
          <CustomImage
            src={images[currentIndex].src || ''}
            alt={images[currentIndex].label || ''}
            width={630}
            height={630}
            priority
          />
        </Link>
      ) : (
        <button type='button' onClick={handleDialog} className={styles.imageContainer}>
          <CustomImage
            src={images[currentIndex].src || ''}
            alt={images[currentIndex].label || ''}
            fill
            priority
            sizes='630px'
          />
        </button>
      )}
      <Dialog open={isOpen} onClose={handleDialog} className={styles.dialog}>
        {/* <Dialog.Overlay className='fixed inset-0 bg-spring-neutral-15 opacity-70 transition-opacity md:block' /> */}
        <Dialog.Panel className={styles.dialogPanel}>
          <button type='button' onClick={handleDialog} className={styles.closeButton}>
            <span className='sr-only'>Close Image Zoom </span>
            <XMarkIcon className={styles.closeButtonIcon} />
          </button>
          <button type='button' onClick={handlePrevImage} className={styles.leftArrow}>
            <span className='sr-only'>Previous Image</span>
            <ChevronLeftIcon className={styles.arrowIcon} />
          </button>
          <div className={styles.imageSize}>
            <CustomImage
              src={images[currentIndex].full ?? ''}
              alt={`zoom-in ${images[currentIndex].label}`}
              width={1200}
              height={1200}
            />
          </div>
          <button type='button' onClick={handleNextImage} className={styles.rightArrow}>
            <span className='sr-only'>Next Image</span>
            <ChevronRightIcon className={styles.arrowIcon} />
          </button>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default ProductImageZoom;
