import React from 'react';
import styles from './index.module.css';

interface QuantityComboBoxProps {
  setQuantity: (value: string | number | undefined) => void;
  quantities: string[];
  value: string | number | undefined;
}

const QuantityComboBox: React.FC<QuantityComboBoxProps> = ({ setQuantity, quantities, value }) => {
  return (
    <select
      className={styles['quantity-select']}
      name='quantity'
      value={value}
      onChange={(e) => setQuantity(e.target.value)}
      role='combobox'
    >
      {quantities.map((q) => {
        const numericValue = parseInt(q, 10);
        return (
          <option key={q} value={q}>
            {numericValue > 9 ? `${numericValue}+` : q}
          </option>
        );
      })}
    </select>
  );
};

export default QuantityComboBox;
