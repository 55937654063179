import React, { useCallback } from 'react';
import ColorComponent from '../ColorComponent';
import styles from './index.module.css';

export interface ProductColor {
  id: number;
  name: string;
  colorCode: string;
}

interface ColorSelectorProps {
  colors: ProductColor[];
  selectedColorId?: number;
  handleColorSelection: (colorId: number) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ colors, selectedColorId, handleColorSelection }) => {
  const handleClick = useCallback(
    (colorId: number) => {
      handleColorSelection?.(colorId);
    },
    [handleColorSelection]
  );

  return (
    <div className={styles.colorBlock} data-testid='color-selector'>
      <ColorComponent
        colors={colors}
        selectedColorId={selectedColorId as any}
        colorClassName={styles.colorClass}
        selectedColorClassName={styles.selectedColor}
        colorsContainerClassName={styles.colorsContainer}
        onColorClick={handleClick}
        labelClass={styles.colorLabel}
        colorLabel
      />
    </div>
  );
};

export default ColorSelector;
