import React, { useCallback } from 'react';
import { RadioGroup } from '@headlessui/react';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import SizeGuide from '@/components/SizeGuide';
import { SizeChartFragment } from '@/gql';
import { isNullish } from '@/lib/utils';
import { SizeOption } from '@/types/size-option';
import styles from './index.module.css';

interface SizeSelectorProps {
  sizes: SizeOption[];
  availableSizes: SizeOption[];
  selectedSize: number | undefined;
  handleSizeSelection: (sizeId: number) => void;
  sizeChart?: SizeChartFragment;
  sizeChartUrl?: string | null | undefined;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({
  sizes,
  availableSizes,
  selectedSize,
  handleSizeSelection,
  sizeChart,
  sizeChartUrl,
}) => {
  const validSizeGuide = !isEmpty(sizeChart) && !isEmpty(sizeChart?.sizes?.[0]?.dimensions?.[0]?.units?.[0].value);

  const displaySizeGuide = validSizeGuide || sizeChartUrl;
  // Mostly for TS type happiness, but make sure id is valid before passing it upstream
  const onSizeChange = useCallback(
    (id?: number) => {
      if (!isNullish(id) && handleSizeSelection) {
        handleSizeSelection(id);
      }
    },
    [handleSizeSelection]
  );

  return (
    <div className={styles.sizeSelector} data-testid='size-selector'>
      <RadioGroup value={selectedSize} onChange={onSizeChange}>
        <div className={styles.labelContainer}>
          <RadioGroup.Label>Size</RadioGroup.Label>
          {displaySizeGuide && <SizeGuide sizeChart={sizeChart} sizeChartUrl={sizeChartUrl} />}
        </div>
        <div className={styles.optionsContainer}>
          {sizes.map((size) => {
            const isAvailable = availableSizes.some((availableSize) => availableSize.label === size.label);
            return (
              <RadioGroup.Option
                data-size-id={size.id}
                key={size.id}
                value={size.id}
                disabled={!isAvailable}
                className={({ active, checked }) =>
                  classNames(
                    styles.group,
                    active || checked ? styles.activeChecked : '',
                    !isAvailable ? styles.unavailable : styles.pointer
                  )
                }
              >
                <RadioGroup.Label className={!isAvailable ? styles.opacity : ''}>{size.label}</RadioGroup.Label>
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
    </div>
  );
};

export default SizeSelector;
