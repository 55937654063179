import React from 'react';
import styles from './index.module.css';

const statusText = {
  available: 'Add to cart',
  notAvailable: 'Out of stock',
};

interface AddtoCartButtonProps {
  availability: boolean;
  loading?: boolean;
}

const AddToCartButton: React.FC<AddtoCartButtonProps> = ({ availability, loading = false }) => {
  const loadingIcon = (
    <div className={styles.loadingIcon} role='status'>
      <span className={styles.loadingIconStatus}>Loading...</span>
    </div>
  );

  const buttonText = availability ? statusText.available : statusText.notAvailable;
  const content = loading ? loadingIcon : buttonText;

  const getButtonClasses = (avail: boolean, load: boolean | undefined) => {
    if (avail) {
      if (load) {
        return styles.availableLoading;
      } else {
        return styles.available;
      }
    } else {
      return styles.notAvailable;
    }
  };

  const buttonClasses = getButtonClasses(availability, loading);

  return (
    <button type='submit' className={buttonClasses}>
      {content}
    </button>
  );
};

export default AddToCartButton;
