import React from 'react';
import FreeReturnPolicy from '@/components/FreeReturnPolicy';
import DigitalReturnDetails from '@/components/DigitalReturnDetails';
import styles from './index.module.css';

export interface DeliveryDetailsProps {
  type: string | undefined;
  shipDate?: string | undefined;
  fulfilledInMexico?: boolean;
}

export const deliveryTypes = {
  physical: 'physical',
  digital: 'digital',
};

const isValidDate = (dateString: string) => {
  return !Number.isNaN(Date.parse(dateString));
};

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({ shipDate, type, fulfilledInMexico }) => {
  const estimatedShipDate = shipDate && isValidDate(shipDate) ? new Date(shipDate) : '';

  return (
    <>
      <div className={styles.deliveryDetails}>Delivery Details</div>
      {type === deliveryTypes.physical && (
        <ul className={styles.deliveryList}>
          {estimatedShipDate && (
            <li>
              Order now and your order will ship by&nbsp;
              {estimatedShipDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </li>
          )}
          <li>Shipping costs will be calculated at checkout</li>
          <li>Worldwide Shipping Available</li>
          <li>Additional Shipping speed options are available at Checkout</li>
          <li>
            Products are fulfilled locally in the United States
            {fulfilledInMexico ? ', Europe and Mexico' : ' and Europe'}.
          </li>
        </ul>
      )}
      {type === deliveryTypes.digital && (
        <div className={styles.instantDownload}>
          A link to download your purchase will be available once your order is processed, which may take a few minutes
          to complete. Please check your inbox for two emails: one confirming your purchase and another containing a
          link to download your digital product.
        </div>
      )}
      <div className={styles.returnDetails}>Return Details</div>
      {type === deliveryTypes.physical ? (
        <>
          <ul className={styles.returnList}>
            <li>
              If your order arrives damaged, misprinted, or defective, we&apos;ll gladly replace it or issue a refund
              within 30 days of delivery date. However, since all products are made to order, we&apos;re unable to
              accept returns or exchanges for different sizes, colors, or change of mind.
            </li>
          </ul>
          <FreeReturnPolicy />
        </>
      ) : (
        <DigitalReturnDetails />
      )}
    </>
  );
};
export default DeliveryDetails;
