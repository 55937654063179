import React from 'react';

const ShieldCheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinejoin='round'
      {...props}
    >
      <title>Shield Check</title>
      <path d='M21 3V16L12 23L3 16V3C3 3 6 1 12 1C18 1 21 3 21 3Z' />
      <path d='M7 11L10 14L17 7' />
    </svg>
  );
};

export default ShieldCheckIcon;
