'use client';

import { useMemo } from 'react';
import { ColorOption } from '@/types/color-option';
import { PrimaryProductFragment, ProductFragment } from '@/gql';

function buildColorOptions(variants?: readonly PrimaryProductFragment[]): ColorOption[] {
  const colorOptions: ColorOption[] = [];
  variants?.forEach((currentProduct?: PrimaryProductFragment) => {
    if (!currentProduct?.variationId || !currentProduct?.color || !currentProduct.attributes?.hex) {
      return;
    }

    colorOptions.push({
      id: currentProduct.variationId,
      name: currentProduct.color,
      colorCode: currentProduct.attributes?.hex,
    });
  });
  return colorOptions;
}

export type ColorOptions = {
  colors: ColorOption[];
};

/**
 * Hook to track color options on a product (aka listing)
 */
export const useProductColorOptions = (product?: ProductFragment): ColorOptions => {
  const colors = useMemo(() => buildColorOptions(product?.primaryProduct || undefined), [product?.primaryProduct]);
  return { colors };
};
