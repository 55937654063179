'use client';

import { useMemo } from 'react';
import { PrimaryProductFragment } from '@/gql';
import { SizeOptions } from '@/types/size-option';
import { buildVariantSizeOptions } from '@/lib/product';

/**
 * Hook to track variant sise options
 */
export const useProductVariantSizeOptions = (variant?: PrimaryProductFragment): SizeOptions => {
  const sizeOptions = useMemo(() => buildVariantSizeOptions(variant), [variant]);
  return sizeOptions;
};
