import React from 'react';
import ColorSelector, { ProductColor } from '@/components/ColorSelector';
import SizeSelector from '@/components/SizeSelector';
import { ProductBlankFragment } from '@/gql';
import { SizeOption } from '@/types/size-option';

interface ProductOptionsProps {
  readonly sizes: SizeOption[];
  readonly availableSizes: SizeOption[];
  readonly colors: ProductColor[];
  selectedSize?: number;
  handleColorSelection: (colorId: number) => void;
  handleSizeSelection: (sizeId: number) => void;
  selectedColorId?: number;
  productDetails: ProductBlankFragment;
}

const ProductOptions: React.FC<ProductOptionsProps> = ({
  sizes,
  colors,
  selectedSize,
  handleColorSelection,
  handleSizeSelection,
  selectedColorId,
  availableSizes,
  productDetails,
}) => {
  const handleClick = (colorId: number) => {
    handleColorSelection(colorId);
  };

  return (
    <>
      {!!colors?.length && (
        <ColorSelector colors={colors} handleColorSelection={handleClick} selectedColorId={selectedColorId} />
      )}
      {sizes && (
        <SizeSelector
          sizes={sizes}
          availableSizes={availableSizes}
          selectedSize={selectedSize}
          handleSizeSelection={handleSizeSelection}
          sizeChart={productDetails?.sizeChart || undefined}
          sizeChartUrl={productDetails?.sizeChartUrl || undefined}
        />
      )}
    </>
  );
};

export default ProductOptions;
