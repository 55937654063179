import React, { useState, useEffect, useCallback, useRef } from 'react';
import QuantityComboBox from '../QuantityComboBox';
import FormTextBox from '../FormTextBox';
import styles from './index.module.css';

interface QuantitySelectorProps {
  value: number | undefined;
  onChange: (value: number) => void;
}

const TEN_PLUS = '10+';
const QUANTITIES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', TEN_PLUS];

const QuantitySelector: React.FC<QuantitySelectorProps> = ({ value, onChange }) => {
  const [quantity, setQuantity] = useState<string | number>(value || '1');
  const [tempQuantity, setTempQuantity] = useState<string | number>(value || '1');
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = useCallback(
    (newValue: string | number | undefined) => {
      if (newValue === TEN_PLUS) {
        setQuantity(TEN_PLUS);
        setTempQuantity(10);
        setShowApplyButton(true);
      } else {
        const currentNewValue = Number(newValue);
        onChange(currentNewValue);
      }
    },
    [onChange]
  );

  const handleTempChange = useCallback(
    (newValue: string) => {
      const parsedValue = parseInt(newValue, 10);
      if (!Number.isNaN(parsedValue)) {
        setTempQuantity(parsedValue);
        setShowApplyButton(parsedValue >= 0 && parsedValue !== value);
      } else {
        setTempQuantity('');
        setShowApplyButton(false);
      }
    },
    [value]
  );

  const handleApplyChange = useCallback(() => {
    setQuantity(tempQuantity);
    onChange(tempQuantity as number);
    setShowApplyButton(false);
  }, [tempQuantity, onChange]);

  useEffect(() => {
    if (quantity === TEN_PLUS && inputRef.current) {
      inputRef.current.select();
    }
  }, [quantity]);

  const displayComboBox = typeof quantity === 'number' && quantity < 10 && quantity > 0;

  return displayComboBox ? (
    <div className={styles.quantitySelectorContainer}>
      <QuantityComboBox value={value} setQuantity={handleChange} quantities={QUANTITIES} />
    </div>
  ) : (
    <FormTextBox
      label='Quantity'
      type='text'
      name='Quantity'
      inputContainerClass={styles.quantityContainer}
      inputClass={`peer ${styles.quantityInput}`}
      setValue={handleTempChange}
      prevValue={tempQuantity?.toString()}
      buttonLabel={showApplyButton ? 'Apply' : ''}
      onInlineButtonClick={handleApplyChange}
      isInlineLabel
      isWarning={false}
      inputRef={inputRef}
    />
  );
};

export default QuantitySelector;
