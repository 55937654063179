import Link from 'next/link';
import styles from './index.module.css';

const DigitalReturnDetails = () => (
  <p className={styles.detailsText}>
    Digital product returns are limited, &nbsp;
    <Link
      href='https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/'
      target='_blank'
      rel='noopener noreferrer'
      className={styles.learnMoreLink}
    >
      learn more
    </Link>
  </p>
);

export default DigitalReturnDetails;
