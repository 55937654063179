'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { ColorId, ColorOption } from '@/types/color-option';

interface ColorComponentProps {
  colors: ColorOption[];
  selectedColorId?: ColorId;
  previewCount?: number;
  colorClassName: string;
  selectedColorClassName: string;
  colorsContainerClassName?: string;
  colorLabel?: boolean;
  labelClass?: string;
  onColorClick?: (colorId: ColorId) => void;
}

const ColorComponent: React.FC<ColorComponentProps> = ({
  colors,
  selectedColorId,
  previewCount,
  colorClassName,
  selectedColorClassName,
  colorsContainerClassName,
  colorLabel = false,
  labelClass = '',
  onColorClick,
}) => {
  const normalizedPreviewCount = Math.max(previewCount || 0, 0) || colors.length;
  const selectedColorIndex = colors.findIndex((c) => c.id === selectedColorId);
  const selectedColor = selectedColorIndex >= 0 ? colors[selectedColorIndex] : undefined;

  // state
  const [showAllColors, setShowAllColors] = useState<boolean>(false);
  const [additionalColors, setAdditionalColors] = useState<number>(0);

  // show all colors if the selected color is not visible in the preview
  useEffect(() => {
    // if a color out of the preview window is selected, show all colors
    if (!showAllColors && selectedColorIndex >= normalizedPreviewCount) {
      setShowAllColors(true);
    }
  }, [selectedColorIndex, showAllColors, normalizedPreviewCount]);

  // keep track of overflow color count
  useEffect(() => {
    const additionalColorCount = showAllColors ? 0 : colors.length - normalizedPreviewCount;
    setAdditionalColors(additionalColorCount);
  }, [colors.length, showAllColors, normalizedPreviewCount]);

  // show all colors handler
  const onClickAdditionalColors = useCallback(() => {
    setShowAllColors(true);
  }, []);

  // color selection handler
  const handleColorClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const candidate = e?.currentTarget?.dataset?.colorId;
      const colorId = candidate !== undefined ? +candidate : Number.NaN;
      if (!Number.isNaN(colorId) && colorId !== selectedColorId && onColorClick) {
        onColorClick(colorId);
      }
    },
    [onColorClick, selectedColorId]
  );

  const displayColors = showAllColors ? colors : colors.slice(0, normalizedPreviewCount);
  const currentColorLabel = `Color: ${selectedColor?.name || ''}`;

  return (
    <>
      {colorLabel && <h3 className={labelClass}>{currentColorLabel}</h3>}
      <div className={colorsContainerClassName}>
        {displayColors?.map((color) => (
          <button
            type='button'
            title={color.name}
            aria-label={color.name}
            key={color.id}
            className={`${colorClassName} ${color.id === selectedColorId ? selectedColorClassName : ''}`}
            style={{ backgroundColor: color.colorCode }}
            onClick={handleColorClick}
            data-color-id={color.id}
          >
            <span className='sr-only'>Select {color.name}</span>
          </button>
        ))}
        {additionalColors > 0 && (
          <button
            type='button'
            title='Show Additional Colors'
            aria-label='Show Additional Colors'
            onClick={onClickAdditionalColors}
          >
            +{additionalColors}
          </button>
        )}
      </div>
    </>
  );
};

export default ColorComponent;
