'use client';

import { useMemo } from 'react';
import { ProductFragment } from '@/gql';
import { buildListingRoute } from '@/lib/navigation';

/**
 * Hook to manage listing product url
 *
 * @param product The listing (aka product in our current naming)
 * @param variationId The color/variation id, if specified
 * @param sizeId The size id, if specified
 * @returns The root relative path to the listing page
 */
export function useListingUrl(product?: ProductFragment, variationId?: number, sizeId?: number): string {
  return useMemo(
    () =>
      buildListingRoute({
        listingSlug: product?.url ?? undefined,
        productId: product?.primaryProductId ?? undefined,
        variationId,
        sizeId,
      }),
    [product?.primaryProductId, product?.url, sizeId, variationId]
  );
}
