import React from 'react';
import Link from 'next/link';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import styles from './index.module.css';

interface ReportProductProps {
  listingSlug: string;
}

const ReportProduct: React.FC<ReportProductProps> = ({ listingSlug }) => {
  const reportLink = `https://teespring.com/policies/report/${listingSlug || ''}`;

  return (
    <div className={styles.reportContainer}>
      <Link href={reportLink} target='_blank' rel='noreferrer' className={styles.reportLink}>
        <ExclamationCircleIcon className={styles.icon} /> <span> Report this product</span>
      </Link>
    </div>
  );
};

export default ReportProduct;
