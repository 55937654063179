import React from 'react';
import CurrencyComponent from '@/components/Currency';
import styles from './index.module.css';

interface ProductHeaderProps {
  name: string;
  price: number;
  productType: string;
  currencyCode: string;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ name, price, productType, currencyCode }) => {
  return (
    <div className={styles.productHeader}>
      <div>
        <h2 className={styles.productName}>{name}</h2>
        <p className={styles.productType}>{productType}</p>
      </div>
      <p className={styles.productPrice}>
        <CurrencyComponent amount={price} currencyCode={currencyCode} />
      </p>
    </div>
  );
};

export default ProductHeader;
