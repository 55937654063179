import React, { useState } from 'react';
import CustomImage from '@/components/CustomImage';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SizeTable from '@/components/SizeTable';
import { SizeChartFragment } from '@/gql';
import analytics from '@/lib/analytics';
import { useStoreData } from '@/hooks/use-store-data';
import styles from './index.module.css';

export interface SizeGuideProps {
  sizeChart?: SizeChartFragment;
  sizeChartUrl?: string | null | undefined;
}

const SizeGuide: React.FC<SizeGuideProps> = ({ sizeChart, sizeChartUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const { storeData } = useStoreData();
  const openModal = () => {
    analytics.track('size_guide', {}, storeData);
    setIsOpen(true);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeModal}>
        <div className={styles.backdrop} aria-hidden='true' />
        <div className={styles.dialogContainer}>
          <Dialog.Panel className={styles.dialogPanel}>
            <button type='button' onClick={closeModal} className={styles.closeButton}>
              <span className='sr-only'>Close size guide</span>
              <XMarkIcon className='w-6 h-6' />
            </button>
            <Dialog.Title className={styles.dialogTitle}>Size Guide</Dialog.Title>
            {sizeChartUrl && <CustomImage src={sizeChartUrl} alt='Size Chart Image' width='678' height='1153' />}
            {!sizeChartUrl && sizeChart && <SizeTable sizeChart={sizeChart} />}
          </Dialog.Panel>
        </div>
      </Dialog>
      <button type='button' className={styles.sizeGuideButton} onClick={openModal}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M13.0908 6.54492L14.5452 7.99933' />
          <path d='M10.9092 8.72656L12.3636 10.181' />
          <path d='M8.72656 10.9102L10.181 12.3646' />
          <path d='M6.54492 13.0918L7.99933 14.5462' />
          <path d='M3.99902 15.6367L15.6361 3.99969L19.9999 8.36357L8.36291 20.0006L3.99902 15.6367Z' />
        </svg>
        <span>Size guide</span>
      </button>
    </>
  );
};

export default SizeGuide;
