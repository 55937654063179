import React, { useState, useRef, useEffect, ReactNode } from 'react';
import Link from 'next/link';

import styles from './index.module.css';

const NAVBAR_HEIGHT = 64;

/**
 * Displays a notice with an icon, description, and an optional link or tooltip for additional information.
 *
 * This component is typically used to present delivery information or similar notices. It includes an icon,
 * a descriptive message, and optionally a link or a tooltip that appears on hover, providing more details.
 * The tooltip's position adjusts dynamically to ensure it remains within the viewport.
 *
 * @component
 * @example
 * return (
 *   <ProductNotice
 *     icon={<Icon />}
 *     noticeDescription="Need delivery by Christmas?"
 *     linkText="View Dates"
 *     helperTextHeader="Delivery by Christmas for:"
 *     helperTextList={["US orders before 12/6", "UK orders before 12/10"]}
 *   />
 * )
 */
interface ProductNoticeProps {
  icon: ReactNode;
  noticeDescription: string;
  linkHref?: string;
  linkText?: string;
  helperTextHeader?: string;
  helperTextList?: string[];
  noticeContainerClassName?: string;
}

const ProductNotice: React.FC<ProductNoticeProps> = ({
  icon,
  noticeDescription,
  linkHref,
  linkText = '',
  helperTextHeader = '',
  helperTextList = [],
  noticeContainerClassName = '',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{ top: number; bottom: Number }>({ top: 0, bottom: 0 });

  const linkRef = useRef<HTMLSpanElement>(null);
  const popupRef = useRef<HTMLSpanElement>(null);

  /**
   * Calculates and sets the position of the tooltip relative to the link element.
   * Ensures the tooltip is displayed within the viewport, adjusting its position
   * based on available space above or below the link.
   */
  useEffect(() => {
    if (isHovered && linkRef.current && popupRef.current) {
      const linkRect = linkRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();

      // Determine if there's enough space above the link; if not, position below
      const currentTop = linkRect.top < popupRect.height + NAVBAR_HEIGHT ? 0 : popupRect.height;
      const currentBottom = linkRect.top < popupRect.height + NAVBAR_HEIGHT ? popupRect.height : 0;

      setPopupPosition({ top: -currentTop, bottom: -currentBottom });
    }
  }, [isHovered]);

  return (
    <div className={`${styles.noticeContainer} ${noticeContainerClassName}`}>
      <span>{icon}</span>
      <div className={styles.noticeText}>
        <span>{noticeDescription}</span>
        {linkHref ? (
          <Link href={linkHref} target='_blank' rel='noopener noreferrer' className={styles.learnMoreLink}>
            {linkText}
          </Link>
        ) : (
          <span
            className={styles.viewLink}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={linkRef}
            data-testid='link-text'
          >
            <span>{linkText}</span>
            {isHovered && helperTextList.length > 0 && (
              <span
                className={styles.helperText}
                style={{
                  top: `${popupPosition?.top}px`,
                  bottom: `${popupPosition?.bottom}px`,
                }}
                ref={popupRef}
                data-testid='popup-helper-text'
              >
                <p className={styles.helpTextHeader}>{helperTextHeader}</p>
                <ul className={styles.helperTextList}>
                  {helperTextList.map((listItem) => (
                    <li key={listItem} className={styles.listItem}>
                      {listItem}
                    </li>
                  ))}
                </ul>
              </span>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProductNotice;
