'use client';

import React, { Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Link from 'next/link';
import { XMarkIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import CartProductListItem from '@/components/CartProductListItem';
import Overlay from '@/components/Overlay';
import { ProductFragment } from '@/gql';
import { useCartQuantity } from '@/hooks/use-cart-quantity';
import { CartItem } from '@/types/cart';
import analytics from '@/lib/analytics';
import { useGoToCheckout } from '@/hooks/use-go-to-checkout';
import styles from './index.module.css';

interface AddedToCartProps {
  product: ProductFragment;
  cartItem: CartItem;
  isOpen: boolean;
  handleClose: () => void;
  source?: string;
}

const AddedToCart: React.FC<AddedToCartProps> = ({ product, cartItem, isOpen, handleClose, source }) => {
  const onClose = useCallback(() => {
    if (handleClose) {
      handleClose();
    }
  }, [handleClose]);
  const { redirectToCheckout, creatingCheckout, canCheckout } = useGoToCheckout();

  const handleCartLink = useCallback(() => {
    analytics.track('cart_link_clicked', { source: 'Added to cart' });
  }, []);

  const handleCheckoutButtonClick = useCallback(() => {
    analytics.track('checkout_button_clicked', { source: source || '' });
    redirectToCheckout();
  }, [redirectToCheckout, source]);

  const cartQuantity = useCartQuantity();
  return (
    <Overlay show={isOpen} onClose={onClose} className={styles.relativeZ40}>
      <div className={styles.fixedInsetFlex}>
        <Transition.Child
          as={Fragment}
          enter='transition-transform-ease-in-out-duration-300'
          enterFrom='translate-y-full md:-translate-y-full'
          enterTo='translate-y-0'
          leave='transition-transform-ease-in-out-duration-300'
          leaveFrom='translate-y-0'
          leaveTo='translate-y-full md:-translate-y-full'
        >
          <Dialog.Panel className={styles.dialogPanelStyle}>
            <div className={styles.dialogTitleContainer}>
              <Dialog.Title as='h3' className={styles.dialogTitleText}>
                Added to cart
              </Dialog.Title>
              <button type='button' className={styles.buttonStyle} onClick={onClose} data-testid='atc_close_x'>
                <span className='sr-only'>Close menu</span>
                <XMarkIcon className={styles.iconStyle} aria-hidden='true' />
              </button>
            </div>
            <div className={styles.productListContainer}>
              <CartProductListItem
                key={product.primaryProductId}
                product={product}
                cartItem={cartItem}
                allowEdit={false}
                imageSize={81}
              />
              <div className='flex justify-space-between space-x-5'>
                <Link href='/cart' className={styles.linkStyle} onClick={handleCartLink}>
                  <ShoppingBagIcon className={styles.iconShoppingBag} aria-hidden='true' />
                  View cart ({cartQuantity})
                </Link>
                <button
                  type='button'
                  disabled={creatingCheckout || !canCheckout}
                  onClick={() => handleCheckoutButtonClick()}
                  className={styles.linkCheckout}
                >
                  {creatingCheckout ? 'Loading...' : 'Checkout'}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Overlay>
  );
};

export default AddedToCart;
