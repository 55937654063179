import React, { useState, useEffect } from 'react';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import { Tab } from '@headlessui/react';
import { ImageFragment } from '@/gql';
import ProductImageSelector from '@/components/ProductImageSelector';
import ProductImageZoom from '@/components/ProductImageZoom';
import { MEDIUM_SCREEN_SIZE } from '@/constants';
import ImageSwiper from '@/components/ImageSwiper';
import styles from './index.module.css';

interface ProductImageDisplayProps {
  images: ReadonlyArray<ImageFragment>;
  productUrl?: string | undefined;
}

const ProductImageDisplay: React.FC<ProductImageDisplayProps> = ({ images, productUrl = undefined }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowDimensions();
  const [screenSize, setScreenSize] = useState(width || 0);
  const isSmallScreen = screenSize < MEDIUM_SCREEN_SIZE;

  useEffect(() => {
    setScreenSize(width || 0);
  }, [width]);

  return isSmallScreen ? (
    <ImageSwiper images={images} activeIndex={activeIndex} quickView={!!productUrl || false} />
  ) : (
    <Tab.Group
      defaultIndex={0}
      selectedIndex={activeIndex}
      onChange={(index) => {
        setActiveIndex(index);
      }}
    >
      {/* Image selection */}
      {images.length > 1 && <ProductImageSelector images={images} activeIndex={activeIndex} />}
      {/* main product image */}
      <Tab.Panels className={styles['product-image-panel']}>
        {images?.map((image) => (
          <Tab.Panel key={image.label}>
            <ProductImageZoom images={images} initialIndex={activeIndex} productUrl={productUrl} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default ProductImageDisplay;
