import React, { useState } from 'react';
import { SizeChartFragment } from '@/gql';
import styles from './index.module.css';

const Unit = {
  Inches: 'inches',
  Centimeters: 'cm',
};

interface SizeTableProps {
  sizeChart: SizeChartFragment;
}

const SizeTable: React.FC<SizeTableProps> = ({ sizeChart }) => {
  const [activeUnit, setActiveUnit] = useState(Unit.Inches);
  const dimensionIds = sizeChart?.sizes?.[0]?.dimensions?.map((dimension) => dimension.id);

  const handleUnitChange = (unit: string) => {
    setActiveUnit(unit);
  };

  const gridClass = dimensionIds ? `gridCols-${dimensionIds.length + 1}` : '';

  return (
    <div className={styles.tableContainer}>
      <div className={styles.flexContainer}>
        <button
          type='button'
          className={`${styles.unitButton} ${
            activeUnit === Unit.Inches ? styles.unitButtonActive : styles.unitButtonInactive
          }`}
          onClick={() => handleUnitChange(Unit.Inches)}
        >
          Inches
        </button>
        <button
          type='button'
          className={`${styles.unitButton} ${
            activeUnit === Unit.Centimeters ? styles.unitButtonActive : styles.unitButtonInactive
          }`}
          onClick={() => handleUnitChange(Unit.Centimeters)}
        >
          Centimeters
        </button>
      </div>
      {dimensionIds && dimensionIds.length > 0 && (
        <div className={`${styles.tableGrid} ${styles[gridClass]}`}>
          <div className={styles.tableCell}>Size</div>
          {dimensionIds?.map((dimensionId) => (
            <div key={dimensionId} className={styles.tableCell}>
              {dimensionId}
            </div>
          ))}
          {sizeChart?.sizes?.map((size, index) => (
            <React.Fragment key={size.id}>
              <div className={`${styles.tableCell} ${index % 2 === 0 ? styles.tableCellStriped : ''}`}>{size.id}</div>
              {size?.dimensions?.map((dimension) => {
                const measurement = dimension?.units?.find((unit) => unit.id === activeUnit);
                return (
                  <div
                    key={dimension.id}
                    className={`${styles.tableCell} ${index % 2 === 0 ? styles.tableCellStriped : ''}`}
                  >
                    {measurement ? measurement.value : ''}
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default SizeTable;
