import React from 'react';
import Link from 'next/link';
import FreeReturnPolicy from '@/components/FreeReturnPolicy';
import { deliveryTypes } from '../DeliveryDetails';
import styles from './index.module.css';

interface DeliveryDetailsProps {
  deliveryType: string;
}

const ReturnDetails: React.FC<DeliveryDetailsProps> = ({ deliveryType }) => {
  return (
    <div className={styles.container}>
      {deliveryType === deliveryTypes.physical ? (
        <FreeReturnPolicy />
      ) : (
        <p className={styles.paragraph}>
          Digital product returns are limited,
          <Link
            href='https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/'
            className={styles.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            learn more
          </Link>
          .
        </p>
      )}
    </div>
  );
};

export default ReturnDetails;
