import React, { useEffect, useState } from 'react';
import CustomImage from '@/components/CustomImage';
import Link from 'next/link';
import { MEDIUM_SCREEN_SIZE } from '@/constants';
import { useStoreData } from '@/hooks/use-store-data';
import CurrencyComponent from '@/components/Currency';
import QuantitySelector from '@/components/QuantitySelector';
import { ProductFragment } from '@/gql';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import { isDigitalItem } from '@/lib/product';
import { buildListingRoute } from '@/lib/navigation';
import useCartState from '@/stores/cart-state';
import { TrashIcon } from '@heroicons/react/24/outline';
import { CartItem } from '@/types/cart';
import analytics from '@/lib/analytics';
import styles from './index.module.css';

interface CartItemProps {
  product: ProductFragment;
  cartItem: CartItem;
  productClassName?: string;
  allowEdit?: boolean;
  imageSize?: number;
}

const CartProductListItem: React.FC<CartItemProps> = ({
  product,
  cartItem,
  allowEdit = true,
  imageSize = 140,
  productClassName,
}) => {
  const { storeData } = useStoreData();

  const { setQuantity, removeCartItem } = useCartState();
  const { width } = useWindowDimensions();
  const [screenSize, setScreenSize] = useState(width || 0);

  if (!product.primaryProduct) throw new Error('Error: No primary product detected');
  const primaryProduct = product?.primaryProduct.find((prod) => prod.variationId === cartItem.variationId);
  if (!primaryProduct?.sizes) throw new Error('Error: No sizes detected');

  const selectedSize = primaryProduct?.sizes.find((size) => size.id === cartItem.sizeId);
  const updateQuantity = (newQuantity: number) => {
    setQuantity(cartItem, newQuantity);
  };

  useEffect(() => {
    setScreenSize(width || 0);
  }, [width]);

  const displaySizeAndColor = () => {
    const sizeDisplay = selectedSize?.label && !isDigitalItem(selectedSize) ? `Size: ${selectedSize?.label}, ` : '';
    const colorDisplay = primaryProduct?.color ? `Color: ${primaryProduct?.color}` : '';
    return sizeDisplay || colorDisplay ? (
      <p>
        {sizeDisplay} {colorDisplay}
      </p>
    ) : null;
  };

  const currencyCode = primaryProduct?.currency || '';
  const handleRemoveItem = (itemToRemove: CartItem) => {
    removeCartItem(itemToRemove);
    analytics.track('remove_from_cart', { ...itemToRemove, product, currency: currencyCode }, storeData);
  };

  const priceNum = Number(selectedSize?.price);
  const total = Number.isNaN(priceNum) ? undefined : priceNum * cartItem.quantity;

  // pick the product image, favoring one for the selected color
  const productImage = primaryProduct?.images?.[0]?.small || product?.images?.[0]?.src;
  const listingUrl = buildListingRoute(cartItem);

  const isMediumScreen = screenSize >= MEDIUM_SCREEN_SIZE;
  const calculatedImageSize = isMediumScreen ? imageSize : 100;

  return (
    <div>
      <div className={`${styles.productContainer} ${productClassName}`}>
        {productImage && (
          <Link className={`${styles.productImageLink} ${styles.productLink}`} href={listingUrl}>
            <CustomImage
              width={calculatedImageSize}
              height={calculatedImageSize}
              alt='Product Image'
              aria-label={`Product description: ${product?.description}`}
              className={styles.productImage}
              src={productImage}
            />
          </Link>
        )}
        <div className={styles.productTitle}>
          {allowEdit ? (
            <Link className={styles.productLink} href={listingUrl}>
              <h4 className={styles.productTitle}>{product.title}</h4>
            </Link>
          ) : (
            product.title
          )}
          <Link className={styles.productLink} href={listingUrl}>
            <div className={styles.productInfo}>
              <p>{product.primaryProduct[0].productType}</p>
              {displaySizeAndColor()}
            </div>
          </Link>
          {allowEdit && (
            <div className={`flex flex-row items-center pt-${isMediumScreen ? '2' : '3'}`}>
              <div className={isMediumScreen ? styles.hiddenMdBlock : styles.quantitySelector}>
                <QuantitySelector onChange={updateQuantity} value={cartItem.quantity} />
              </div>
              <div
                role='button'
                aria-label='Remove item from cart'
                onKeyDown={() => {}}
                onClick={() => handleRemoveItem(cartItem)}
                tabIndex={0}
                className={styles.trashContent}
              >
                <TrashIcon className={styles.iconStyle} />
              </div>
            </div>
          )}
        </div>
        {total && (
          <div className={styles.currencyContent}>
            <p>
              <CurrencyComponent currencyCode={currencyCode} amount={total} />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartProductListItem;
