import React, { useState } from 'react';
import { ImageFragment } from '@/gql';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import 'swiper/css';
import 'swiper/css/virtual';
import { Zoom, Thumbs } from 'swiper/modules';
import CustomImage from '../CustomImage';
import styles from './index.module.css';

interface ImageSwiperProps {
  images: ReadonlyArray<ImageFragment>;
  activeIndex?: number;
  quickView?: boolean;
}

const ImageSwiper: React.FC<ImageSwiperProps> = ({ images, activeIndex, quickView = false }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  const handleDialog = () => {
    if (!quickView) {
      setIsOpen(!isOpen);
    }
  };

  if (!images?.length) {
    return null;
  }

  return (
    <div>
      <Swiper
        slidesPerView={1}
        loop
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        onSwiper={(swiper) => setCurrentIndex(swiper.activeIndex)}
      >
        {images?.map((image) => {
          return (
            <SwiperSlide key={image.label}>
              <button
                type='button'
                onClick={handleDialog}
                className={styles.imageContainer}
                data-testid={`swipe-main-${image.label}`}
              >
                <CustomImage src={image.src || ''} alt={`zoom-in ${image.label}`} width={630} height={630} />
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {images.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          modules={[Thumbs]}
          watchSlidesProgress
          centerInsufficientSlides
          slidesPerView={images.length}
          className={styles.thumbNavigation}
        >
          {images?.map((image, index) => {
            return (
              <SwiperSlide key={image.label} virtualIndex={index} className={styles.thumbItem}>
                <CustomImage src={image.src || ''} alt={`thumbnail ${image.label}`} width={50} height={50} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <Dialog open={isOpen} onClose={handleDialog} className={styles.dialog}>
        {/* <Dialog.Overlay className='fixed inset-0 bg-spring-neutral-15 opacity-70 transition-opacity md:block' /> */}
        <Dialog.Panel className={styles.dialogPanel}>
          <button type='button' onClick={handleDialog} className={styles.closeButton}>
            <span className='sr-only'>Close Image Zoom </span>
            <XMarkIcon className={styles.closeButtonIcon} />
          </button>
          <div className={styles.zoomedImage}>
            <Swiper slidesPerView={1} loop zoom modules={[Zoom]} initialSlide={currentIndex}>
              {images?.map((image) => {
                return (
                  <SwiperSlide key={image.label}>
                    <div className='swiper-zoom-container'>
                      <CustomImage src={image.full || ''} alt={`zoom-in ${image.label}`} width={500} height={500} />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default ImageSwiper;
