import ProductNotice from '@/components/ProductNotice';
import ShieldCheckIcon from '@/components/Icons/ShieldCheckIcon';

import styles from './index.module.css';

const FreeReturnPolicy = () => {
  return (
    <ProductNotice
      icon={<ShieldCheckIcon className={styles.iconStyle} />}
      noticeDescription='30 Day Make It Right Policy'
      linkHref='https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/'
      linkText='Learn more'
      noticeContainerClassName={styles.policyContainer}
    />
  );
};

export default FreeReturnPolicy;
